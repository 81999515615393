import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import useInterval from "@rooks/use-interval";
import { customTranslateText, translateText } from "../../helpers/utils";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import useMediaDevices from "../../helpers/customHooks/usePermissionTracker";
import { useTranslation } from "react-i18next";
import { delay } from "lodash";

interface IProgressBarProps {
  classes?: any;
  testIsLoading?: boolean;
  currentTestCount?: number;
  testsCount?: number;
  currentTestsTime?: any;
  theme?: string;
  textDictionary?: Record<string, string>;
  cancelable?: boolean;
  config?: TestConfiguration;
}

function mapStateToProps(state: any) {
  return {
    testIsLoading: state.document.testIsLoading,
    currentTestCount: state.document.currentTestCount,
    currentTestsTime: state.document.currentTestsTime,
    testsCount: state.document.testsCount,
    theme: state.document.theme,
    textDictionary: state.tests.textDictionary,
    cancelable: state.tests.cancelable,
    config: state.tests.config,
  };
}

const styles = (theme: any): any => ({
  paper: {
    position: "absolute" as "absolute",
    width: 390,
    backgroundColor: "#fff",
    padding: "20px 16px 20px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("xs")]: {
      width: "92%",
    },
  },
  title: {
    display: "block",
    textAlign: "center" as "center",
    fontSize: 20,
    fontWeight: 600,
    padding: "0 0 10px",
    borderBottom: "1px solid #c9cbd1",
    marginBottom: 10,
    color: theme.palette.primary.main,
  },
  title_mend: {
    fontWeight: 500,
  },
  title_amazon: {
    fontWeight: 400,
  },
  title_avatour: {
    color: "#223D4F",
  },
  cont: {},
  text: {
    display: "block",
    textAlign: "center" as "center",
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: 12,
  },
  text_mend: {
    fontWeight: 500,
  },
  text_amazon: {
    fontWeight: 400,
  },
  text_avatour: {
    color: "#223D4F",
  },
  progress: {
    height: 22,
    borderRadius: 30,
  },
  newProgress: {
    width: 320,
    height: 24,
    borderRadius: 30,
  },
  progress_avtour: {},
  progress_zaurus: {
    backgroundColor: "#9ad4fd",
  },
  buttonAbort: {
    display: "block",
    margin: "0 auto",
    marginTop: 24,
    padding: 2,
    paddingLeft: 16,
    paddingRight: 16,
    "&:hover": {
      background: "#478ec7",
      opacity: 0.8,
    },
  },
  // buttonAbortLabel: {
  //   display: "inline-block !important",
  // },
  progressBarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "48px",
  },
  descSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    alignText: "center",
    "& h1": {
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "37.5px",
      textAlign: "center",
      color: theme.newPalette.text.primary,
      fontFamily: "Roboto, sans-serif !important",
    },
    "& p": {
      lineHeight: "24px",
      letterSpacing: "0.15px",
      fontFamily: "Roboto, sans-serif !important",
      color: theme.newPalette.text.primary,
    },
  },
  cancelBtn: {
    padding: "8px 22px",
    color: theme.newPalette.primary.contrast,
    backgroundColor: theme.newPalette.primary.testrtc.main,
  },
});

const ProgressBar = (props: IProgressBarProps) => {
  // navigator.mediaDevices
  //   .enumerateDevices()
  //   .then(function (devices) {
  //     devices.forEach(function (device) {
  //       console.log(device.kind + ": " + device.label + " id = " + device.deviceId);
  //     });
  //   })
  //   .catch(function (err) {
  //     console.log(err.name + ": " + err.message);
  //   });
  const { cancelable, classes, testIsLoading, currentTestsTime, textDictionary, theme, config } = props;
  const { t, i18n } = useTranslation(["common", "tests"]);

  // useEffect(() => {
  //   if (config) {
  //     if (config.getUserMedia) {
  //       if (config.getUserMedia.checkAudio || config.getUserMedia.checkVideo) {
  //         if (navigator) {
  //           navigator.mediaDevices
  //             .enumerateDevices()
  //             .then(function (devices) {
  //               devices.forEach(function (device) {
  //                 if (
  //                   config?.getUserMedia?.checkAudio &&
  //                   device.kind === "audioinput" &&
  //                   device.deviceId &&
  //                   device.deviceId !== ""
  //                 ) {
  //                 }
  //                 console.log(device.kind + ": " + device.label + " id = " + device.deviceId);
  //               });
  //             })
  //             .catch(function (err) {
  //               console.log(err.name + ": " + err.message);
  //             });
  //         }
  //       }
  //     }
  //   }
  // }, [config]);
  // for some reason value is not reliable reset does not work beocuse race with Value + 1
  const counterRef = useRef({
    value: 0,
    start: 0,
  });
  const [value, setValue] = useState(0);
  const [msgIndex, setMsgIndex] = useState(0);

  // const progress = (currentTestCount / testsCount) * 100;

  if (!textDictionary) {
    throw new Error("Missing parameter");
  }
  let preloadText = "";
  const totalTime = currentTestsTime?.totalTime || 0;
  if (totalTime) {
    const minutes = Math.ceil(totalTime / 60);
    if (config?.multipleProgressBarMessage) {
      const totalNumberOfMsgs = config?.messageLength || 1;
      preloadText = customTranslateText(
        t(`tests:textDictionary.${config?.theme}.progress-bar-msg.${[msgIndex]}`),
        t("tests:progressbar.message"),
        i18n.exists(`tests:textDictionary.${config?.theme}.progress-bar-msg.${[msgIndex]}`)
      )
        .replace("{time}", minutes.toString())
        .replace("{minute}", minutes <= 1 ? "minute" : "minutes");
      delay(() => {
        setMsgIndex(msgIndex === totalNumberOfMsgs - 1 ? 0 : msgIndex + 1);
      }, 10000);
    } else {
      preloadText = customTranslateText(
        t(`tests:textDictionary.${config?.theme}.progress-bar-msg`),
        t("tests:progressbar.message"),
        i18n.exists(`tests:textDictionary.${config?.theme}.progress-bar-msg`)
      )
        .replace("{time}", minutes.toString())
        .replace("{minute}", minutes <= 1 ? "minute" : "minutes");
    }
  }

  const moveProgress = () => {
    counterRef.current.value += 1;

    // To refresh the UI
    setValue(value + 1);
  };

  const { start } = useInterval(moveProgress, 1000);

  useEffect(() => {
    if (currentTestsTime) {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTestsTime, testIsLoading]);

  let progressBarPos = 0;

  if (currentTestsTime) {
    const { passedAtStart, passedAtEnd, totalTime } = currentTestsTime;
    if (counterRef.current.start !== passedAtStart) {
      // reset counter for new test
      counterRef.current.value = 0;
      counterRef.current.start = passedAtStart;
    }

    progressBarPos = (Math.min(passedAtEnd, passedAtStart + counterRef.current.value) / totalTime) * 100;
  }

  const handleAbortButton = () => {
    const { abort } = currentTestsTime;
    if (typeof abort === "function") {
      abort();
    }
  };

  if (config?.useNewWidgetDesign) {
    return (
      <div className={classes.progressBarWrapper}>
        <div className={classes.descSection}>
          <Typography variant="h1">{t("tests:newProgressBar.title")}</Typography>
          <Typography variant="body1">{t("tests:newProgressBar.time", { minutes: Math.ceil(totalTime / 60).toString() })}</Typography>
        </div>
        <LinearProgress value={progressBarPos} variant="determinate" className={`${classes.newProgress}`} id="progress-bar" />

        <Button className={`${classes.cancelBtn}`} onClick={handleAbortButton} type="button">
          {t("tests:newProgressBar.cancel")}
        </Button>
      </div>
    );
  }

  return (
    <Modal open={testIsLoading || false}>
      <div className={classes.paper}>
        <Typography variant="h6" className={`${classes.title} ${classes[`title_${theme} loadingTitle`]}`}>
          {theme == "availconsole" ? t("tests:progressbar:title2") : t("tests:progressbar:title")}
        </Typography>
        <div className={`${classes.cont} loadingProgressClass`}>
          {theme == "availconsole" ? (
            <p className={`availLoading`}>Should be completed in approximately {Math.ceil(totalTime / 60).toString()} minutes</p>
          ) : (
            ""
          )}
          <span className={`${classes.text} ${classes[`text_${theme}`]}`}>{preloadText}</span>
          {/* {!isHideMessage && (
            <span className={`${classes.text} ${classes[`text_${theme}`]}`}>
              {t("tests:progressbar:subtext")}
            </span>
          )} */}

          <LinearProgress
            className={`${classes.progress} ${classes[`progress_${theme} linearProgress`]}`}
            variant="determinate"
            value={progressBarPos}
            id="progress-bar"
          />
          {cancelable && (
            <Button
              className={`${classes.buttonAbort} buttonAbort_${theme}`}
              variant="contained"
              onClick={handleAbortButton}
              type="button"
              color="primary"
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, null)(withStyles(styles)(ProgressBar));
